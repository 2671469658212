import moment from 'moment';
import { filter, map, sortBy, uniq } from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import { oauth, formatMoney } from '../utils';
import { getPopups } from '../selectors';
import ErrorBoundary from '../components/ErrorBoundary';
import Overlay from '../components/Overlay';
import Select from '../components/Select';
import Loading from '../components/Loading';
import Header from './Header';
import MainSection from './MainSection';

import '../../scss/css/ps_charges_report.css';

const PSChargesReportApp = ({ popups }) => {
  const adminNavRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [charges, setCharges] = useState([]);
  const [filters, setFilters] = useState({division: '', po_year: moment().subtract(1, 'month').format('YYYY')});
  useEffect(() => {
    // for compatibility with old jquery team/tools page, should convert the whole page to react
    const adminNav = document.getElementById('admin-nav');
    adminNav.style.display = 'inline-block';
    adminNavRef.current.append(adminNav);
  }, []);

  useEffect(() => {
    oauth('GET', 'charge/epo-charges-per-month', {}).then(({ json }) => {
      setCharges(json.charges || []);
      setLoading(false);
    });
  }, []);

  const years = Array.from(new Set(charges.reduce(
    (o, c) => [...o, ...c.po_month.split(',').map(d => moment(d).format('YYYY'))],
    []
  ))).sort().map(y => ({ key: y, value: y }));

  return <ErrorBoundary>
    <Header/>
    <MainSection popups={popups}>
      <div className="main-content">
        <div ref={adminNavRef} className="row" style={{ height: 50 }}></div>
        <div className="row">
          <div className="columns small-4">
            <Select
              placeholder="Select a Division"
              options={uniq(map(charges, 'division_name')).map((division_name) => {
                return {key: division_name, value: division_name};
              })}
              value={filters.division}
              change={(value) => {
                setFilters({...filters, division: value});
              }}
            />
          </div>
          <div className="columns small-4">
            <Select
              placeholder="Select a Year"
              options={years}
              value={filters.po_year}
              change={(value) => {
                setFilters({...filters, po_year: value});
              }}
            />
          </div>
        </div>
        <div className="row">
          {loading ? <Loading style={{ margin: '0 auto', display: 'block'}}/> : <table className="outer-table">
            <thead>
              <tr>
                <td>Division</td>
                <td>PO Date</td>
                <td>PO Total</td>
                <td>Charged Fee Total</td>
                <td>Currency Rate</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {map(sortBy(charges.filter(
                c => (!filters.division || c.division_name === filters.division) &&
		     (!filters.po_year || c.po_month.split(',').some(d => moment(d).format('YYYY') === filters.po_year))
              ), '-po_month'), (row, i) => {
                return <tr key={i} className="outer-row">
                  <td>{row.division_name}</td>
                  <td>{row.po_month}</td>
                  <td>{formatMoney(row.total)} {row.charged_currency}</td>
                  <td>{formatMoney(row.charged_fee)} {row.charged_currency_converted}</td>
                  <td>{formatMoney(row.charged_rate_converted)}</td>
                  <td onClick={() => {
                    oauth('GET', 'report/supplier-epo-charges', {
                      division_id: row.division_id,
                      start_stamp: moment(row.charged_date).format('YYYY-MM-DD HH:mm:ss'),
                      end_stamp: moment(row.charged_date).add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
                      export_all: 1
                    }, null, {
                      headers: {'Content-Type': 'text/csv', Accept: 'text/csv'},
                      responseType: 'blob'
                    }, (response) => {
                      response.text().then((csv) => {
                        const link = document.createElement('a');
                        link.href = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(csv);
                        link.download = `ps-charges-${row.division_name}-${row.po_month}.csv`;
                        link.click();
                      });
                    });
                  }}>Details CSV</td>
                </tr>;
              })}
            </tbody>
          </table>}
        </div>
      </div>
    </MainSection>
    <Overlay popups={popups} />
  </ErrorBoundary>;
};

const mapStateToProps = (state, ownProps) => {
  return {
    popups: getPopups(state),
  };
};

export default connect(mapStateToProps)(PSChargesReportApp);
